import styled from 'styled-components'
import { colors, fonts } from '../theme'

const Highlight = styled.span`
  font-size: 1.1em;
  line-height: 1;
  font-weight: 700;
  border-bottom: 2px solid ${colors.accent};
`

const Subheading = styled.h2`
  font-size: 1.5rem;
  font-family: ${fonts.primary};
`

const TextOnlyContainer = styled.div`
  max-width: 680px;
  margin: 0 auto;
  font-size: 1.125rem;

  p {
    line-height: 1.5;
  }
`

export { Highlight, Subheading, TextOnlyContainer }
