import React, { Fragment } from 'react'
import styled from 'styled-components'
import { colors } from '../components/theme'

export default styled(
  ({
    className,
    src = 'https://placehold.it/250x100',
    alt = '',
    caption,
    ...props
  }) => (
    <Fragment>
      <img src={src} alt={alt} className={className} {...props} />
      {caption && <p className="caption">{caption}</p>}
    </Fragment>
  )
)`
  display: block;

  .caption {
    font-size: 0.875rem;
    color: ${colors.grey};
  }
`
