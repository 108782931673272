import React from 'react'
import Helmet from 'react-helmet'
import styled from 'styled-components'
import documentTitle from '../../utils/documentTitle'
import Layout from '../../layouts'

import PageWrapper from '../../components/PageWrapper'
import ImgComponent from '../../components/Img'
import { TextOnlyContainer } from '../../components/common/Texts'
import ArticleHeader from '../../components/common/ArticleHeader'
import Link from '../../components/common/Link'

import imgMain from '../../images/articles/cvca-main.png'
import imgStaircase from '../../images/articles/cvca-1.png'

const Img = styled(ImgComponent)`
  margin: 2rem auto;
  width: 95%;
`

//
//  Main
export default () => (
  <Layout>
    <Helmet>
      <title>{documentTitle('The CVCA Diversity & Inclusion Program')}</title>
      <meta property="og:image:secure_url" content={imgMain} />
    </Helmet>

    <PageWrapper>
      <ArticleHeader
        date="July 11, 2018"
        title="
          The CVCA Diversity & Inclusion Program – a conversation with Whitney
          Rockley"
        imgSrc={imgMain}
        imgAlt="CVCA: Canadian Venture Capital & Private Equity Association"
      />
      <section>
        <TextOnlyContainer>
          <p>
            The{' '}
            <Link href="https://www.cvca.ca/">
              Canadian Venture Capital & Private Equity Association (CVCA)
            </Link>{' '}
            is the voice of Canada’s venture capital and private equity
            industry. With a membership comprised of the firms managing the vast
            majority of venture capital and private equity in Canada, the CVCA
            works alongside its members to both improve the industry and drive
            innovation and growth.
          </p>
          <p>
            Whitney Rockley, who from 2017 – 2018 was{' '}
            <Link href="https://betakit.com/mcrock-capitals-whitney-rockley-becomes-first-woman-to-chair-cvca/">
              the first woman to chair the CVCA
            </Link>
            , is currently co-chairing a program the CVCA has launched to both
            celebrate the current contribution of women in Canadian private
            capital and to create a more diverse ecosystem.
          </p>
          <p>
            The{' '}
            <Link href="http://www.cvca.ca/staff/governance/committees/diversity-taskforce/">
              CVCA Diversity and Inclusion Program
            </Link>{' '}
            began as a conversation between Mike Woollatt and{' '}
            <Link href="https://www.mcrockcapital.com/team.html">
              Whitney Rockley
            </Link>
            , Founder & Managing Partner at McRock Capital and then vice-Chair
            of CVCA. Rockley recalls that both she and Woollatt felt that the
            industry was struggling with diversity & inclusion across the board
            – but that it was hard to know where to start. It was frustrating
            that “from a basic research standpoint, we didn’t even have baseline
            data.”
          </p>
          <p>
            Rockley and Woollatt floated an idea: could the CVCA collect a
            library of existing resources, data, and tools that member firms
            could use? Six CVCA members volunteered to help drive a committee
            forward. The committee has since grown into a full-fledged program
            of the CVCA powered by over 20 members and staff.
          </p>
          <p>
            The program aims to ensure that that member firms “don’t have to
            start from zero” when building policies or practices to ensure more
            inclusive workplaces. By making use of the tools created to increase
            their own awareness and education, leveraging the studies and
            training opportunities, and participating in mentorship and
            sponsorship programs, firms can learn from industry best practices
            and adopt the tools that are working for other organizations.
          </p>
          <h3>“The data is not surprising,”</h3>
          <p>
            says Rockley, after reviewing the Women in Venture report. “Venture
            Capital is an apprenticeship business,” with a career trajectory
            that involves 5 key steps: analyst, associate, principal, partner,
            and then managing partner. “We need to figure out how to build
            inclusive practices, so female analysts continue to advance to the
            partner ranks.”
          </p>
          <Img
            src={imgStaircase}
            alt="With each step up the “staircase” of seniority, representation of women is cut in half. At Canadian VC firms, women hold: 58% of analyst roles, 27% of associate &amp; principal roles, 14% of partner roles"
          />
          <p>
            But it’s not just about nurturing the junior talent already in the
            industry. Rockley also believes the data reveals an opportunity to
            make change at the senior leadership level, today: “There are no
            venture partners that are women – that is such an ‘Aha!””
          </p>
          <p>
            There are few, if any, female venture partners – yet the number of
            women that are accredited investors is growing quickly. Rockley sees
            a large opportunity to entice these women to pursue venture partner
            roles in Canadian VC firms.
          </p>
          <h3>
            “I believe our industry will demonstrate stronger performance
            through diversity of thought,”
          </h3>
          <p>
            says Rockley. “Diverse teams bring valuable perspectives and
            approaches to the ideation process resulting in more innovative
            solutions to complex problems, which is what our industry does and
            needs to continue to do.”
          </p>
          <p>
            That means building workplaces and an industry that intentionally
            makes space at the table: “The key is building an industry where our
            talent – regardless of ethnicity, disability, orientation and gender
            – can thrive in a career in private capital.”
          </p>
          <hr />
          <p>
            Read the full Women in Venture Report{' '}
            <Link to="/women-in-venture">here</Link>
          </p>
        </TextOnlyContainer>
      </section>
    </PageWrapper>
  </Layout>
)
