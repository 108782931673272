import React from 'react'
import styled from 'styled-components'
import { colors } from '../theme'

export default styled(
  ({ className, date, label, title, subtitle, heroImage }) => (
    <div className={className}>
      <div>
        <span className="date">{date}</span>
        <span className="label">{label}</span>
      </div>

      <h1 className="title">{title}</h1>
      {heroImage && <img src={heroImage.fluid.src} alt={heroImage.title} />}
      <p>
        <em>{subtitle}</em>
      </p>
    </div>
  )
)`
  margin: 4rem 0 2rem;
  padding: 0 2rem;
  text-align: center;

  .date,
  .label {
    margin: 0;
    color: ${colors.grey};
    margin: 0 0.5rem;
    font-size: 0.875rem;
  }

  .title {
    margin: 0.25em 0 0.75em;
    font-size: 2.75rem;
    font-weight: 400;
    line-height: 1.1;
  }
`
